import React, { useState, useEffect } from 'react'
import Sidenav from '../Template/Sidenav'
import Navbar from '../Template/Navbar'
import { useLocation, useNavigate } from 'react-router-dom'
// import { Link } from 'react-router-dom'

const URL = process.env.REACT_APP_URL;


const Editpages = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id, pagename, seotitle, seodescription, seoimageurl, seokeyword } = location.state;
  const [pages, setpages] = useState({ pagename: "", seotitle: "", seodescription: "", seoimageurl: "", seokeyword: "" });
  // let name, value
  const HandleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setpages({ ...pages, [name]: value });
  }
  useEffect(() => {
    setpages({ pagename, seotitle, seodescription, seoimageurl, seokeyword });// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = () => {
    const postURL = `${URL}/pages/updatePage/${id}`
    const { pagename, seotitle, seodescription, seoimageurl, seokeyword } = pages;

    fetch(postURL, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ pagename, seotitle, seodescription, seoimageurl, seokeyword })

    })
      .then(res => {
        return res.json();
      }).then(data => {
        navigate('/pages')
        window.alert(" updated Successfully ");
      })
  }

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-2'>
            <Sidenav />
          </div>
          <div className='col-md-10'>
            <Navbar />
            <form >
              <div className='row'>
                <div className='col-md-4'>
                  <div className="mb-3 mt-3">
                    <label for="country">pagename:</label>
                    <input type="text" className="form-control" id="pagename" placeholder="pagename" value={pages.pagename} name="pagename" readonly />
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className="mb-3 mt-3">
                    <label for="country">Seotitle:</label>
                    <input type="text" className="form-control" id="iconname" placeholder="Update seotitle" value={pages.seotitle} name="seotitle" onChange={HandleInput} />
                  </div>

                </div>

                <div className='col-md-4'>


                </div>


                <div className='col-md-4'>
                  <div className="mb-3 mt-3">
                    <label for="country">Seo Image Url:</label>
                    <input type="text" className="form-control" id="iconname" placeholder="Seo Image Url" value={pages?.seoimageurl} name="seoimageurl" onChange={HandleInput} />
                  </div>

                </div>
                <div className='col-md-4'>
                  <div className="mb-3 mt-3">
                    <label for="country">Seo Keyword:</label>
                    <input type="text" className="form-control" id="iconname" placeholder="Seo Keyword" value={pages?.seokeyword} name="seokeyword" onChange={HandleInput} />
                  </div>

                </div>
                <div className='col-md-4'>
                </div>

                <div className='col-md-12'>
                  <label for="country">Seo Description:</label>
                  <div className="mb-3 mt-3">
                    <textarea className='w-75' type="text" id="iconname" placeholder="Seo Description" value={pages?.seodescription} name="seodescription" onChange={HandleInput} rows="6" />
                  </div>

                </div>
              </div>
            </form>
            <div className='col-md-4'>
              <div className="mb-3 mt-3">
                <button type="submit" className="btn " onClick={() => handleSubmit()}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Editpages