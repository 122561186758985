import React from 'react'
import Sidenav from '../Template/Sidenav'
import Navbar from '../Template/Navbar'
import { Link } from 'react-router-dom'
const URL = process.env.REACT_APP_URL;

const Form = () => {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-2'>
          <Sidenav />
        </div>
        <div className='col-md-10'>
          <Navbar />
          <div className='row mt-5'>
            <div className='col-md-3'>
            <div className=' dashboard-inner'>
              <div className='dashboard-text'>
              <h5>FORM</h5>
              <Link className="nav-link" to="/form-one">Form-One</Link>
              </div>
            </div>
            </div>
            <div className='col-md-3'>
            <div className=' dashboard-inner'>
              <div className='dashboard-text'>
              <h5>FORM</h5>
              <Link className="nav-link" to="/form-two">Form-two</Link>
              </div>
            </div>
            </div>
            <div className='col-md-3'>
            <div className=' dashboard-inner'>
              <div className='dashboard-text'>
              <h5>FORM</h5>
              <Link className="nav-link" to="/form-three">Form-three</Link>
              </div>
            </div>
            </div>
            <div className='col-md-3'>
            <div className=' dashboard-inner'>
              <div className='dashboard-text'>
              <h5>FORM</h5>
              <Link className="nav-link" to="/form-one">Form-One</Link>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Form