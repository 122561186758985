import React, { useEffect, useState } from "react";
import Sidenav from "../Template/Sidenav";
import Navbar from "../Template/Navbar";
import Login from "./LOgin";
import { Link } from "react-router-dom";
import star from "../img/star.png";
const URL = process.env.REACT_APP_URL;

const Post = () => {
  const [deleteid, Setdeleteid] = useState("");
  const usertoken = sessionStorage.getItem("token");
  const [category, Setcategory] = useState("");
  const [post, Setpost] = useState("");
  // Modal number view start
  const [isOpen, setIsOpen] = useState(false);
  const [postClickId, setPostClickId] = useState("");
  const [numberInput, setNumberInput] = useState("");

  // Function to handle input change
  const handleInputChange = (event) => {
    setNumberInput(event.target.value);
  };
  const formatDate2 = (dateString) => {
    const date = new Date(dateString);
    // Add one day
    // date?.setDate(date?.getDate() + 1);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date?.toLocaleDateString('en-US', options);


  };
  // Function to handle button click
  const handleButtonClick = () => {
    closeModal();
    const postURL = `${URL}/postad/update_Post/${postClickId}`;

    fetch(postURL, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ numViews: numberInput === "" ? 0 : numberInput }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        getallpost();

        window.alert("Count updated Successfully ");
      });
  };
  const openModal = (val) => {
    setIsOpen(true);
    setPostClickId(val?._id);
    setNumberInput(val?.numViews);
  };

  // Function to handle closing modal
  const closeModal = () => {
    setIsOpen(false);
  };
  // Modal For Number Views end
  const getcategory = () => {
    fetch(`${URL}/category/getallcategory`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        Setcategory(data);
      });
  };
  const getallpost = (id) => {
    fetch(`${URL}/postad/getallpostad`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        Setpost(data);
      });
  };
  const getpost = (id) => {
    fetch(`${URL}/postad/getpostadby_category_id/${id}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        Setpost(data);
      });
  };
  useEffect(() => {
    getcategory();
    getallpost();
  }, []);

  function deleteUser(_id) {
    fetch(
      `${URL}/postad/deletepostadby_single_id/${_id}`,
      {
        method: "DELETE",
      }
    ).then((result) => {
      result.json().then((res) => {
        window.alert("Post removed");
        getallpost();
      });
    });
  }

  if (!usertoken) {
    return <Login />;
  }
  const handleStatus = async (id, e) => {
    const value = e.target.value;
    fetch(`${URL}/postad/updatePostStatus/${id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ isActive: value }),
    }).then((result) => {
      result.json().then((res) => {
        getallpost();
      });
    });
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          <Sidenav />
        </div>
        <div className="col-md-10">
          <Navbar />

          <form>
            <div className="row">
              <div className="col-md-4">
                {/* <div className="mb-3 mt-3">
                  <select
                    class="form-select form-control"
                    id="sel1"
                    name="sellist1"
                    onChange={(e) => getpost(e.target.value)}
                  >
                    {category.data?.map((val, index) => {
                      return (
                        <option key={index} value={val._id}>
                          {val.name}
                        </option>
                      );
                    })}
                  </select>
                </div> */}
              </div>
              <div className="col-md-4"></div>
              <div className="col-md-4"></div>
            </div>
          </form>

          <div class="table-responsive text-center mt-5">
            <table class="table ">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Highlighted</th>
                  <th>Title</th>

                  <th>Post Count / Edit</th>
                  <th>Order Id</th>
                  {/*  <th>edit</th>*/}
                  <th>View</th>
                  <th>Verified</th>
                  <th>Post Status</th>
                  <th>Payment Status</th>
                  <th>Post Expired</th>
                  <th>Expiry Date</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {post?.data?.map((val, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>

                      <td>
                        {" "}
                        {val?.highlight ? (
                          <img src={star} className="profile-img" alt="ddf" />
                        ) : null}
                      </td>
                      <td> {val?.name}</td>
                      <td onClick={() => openModal(val)}>{val?.numViews}</td>
                      <td>{val?.orderid ? val?.orderid : "-"}</td>
                      {/*  <td><Link to="/edit-post" state={{ id: val?._id, keywords: val?.keywords, metatitle: val?.metatitle, metadescription: val?.metadescription }}><span class="material-symbols-outlined" style={{ color: 'gray' }}>edit</span></Link></td>*/}
                      <td>
                        {" "}
                        <Link to="/Post-detail" state={{ data: val }}>
                          <span
                            class="material-symbols-outlined"
                            style={{ color: "gray" }}
                          >
                            visibility
                          </span>
                        </Link>
                      </td>

                      <td>
                        {val?.isVerified === true ? (
                          <span
                            class="material-symbols-outlined"
                            style={{ color: "gray" }}
                          >
                            <span class="material-symbols-outlined">
                              verified
                            </span>
                          </span>
                        ) : (
                          <span
                            class="material-symbols-outlined"
                            style={{ color: "gray" }}
                          ></span>
                        )}
                      </td>
                      <td>
                        <select
                          className="form-control"
                          id="exampleSelectGender"
                          name="status"
                          onChange={(e) => handleStatus(val._id, e)}
                        >
                          {val?.isActive === true ? (
                            <option value="" disabled selected hidden>
                              Active
                            </option>
                          ) : (
                            <option value="" disabled selected hidden>
                              Inactive
                            </option>
                          )}

                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                      </td>
                      <td>
                        {" "}
                        {val?.paymentstatusdetail.charAt(0).toUpperCase() + val?.paymentstatusdetail.slice(1)
                         }
                      </td>
                      <td>
                        {" "}
                        {
                          val?.ispostexpired?"Expired":"No"
                         }
                      </td>
                      <td>
                        {" "}
                        {
                          

                         }
                         {formatDate2(val?.expirydate?.split('T')[0])}
                      </td>
                      <td>
                        {" "}
                        <span
                          style={{ cursor: "pointer", color: "gray" }}
                          class="material-symbols-outlined"
                          data-bs-toggle="modal"
                          data-bs-target="#myModal"
                          onClick={() => {
                            Setdeleteid(val._id);
                          }}
                        >
                          delete
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="modal fade" id="myModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">Do You Want To Delete Permanently</div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn"
                data-bs-dismiss="modal"
                onClick={() => deleteUser(deleteid)}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="modal2">
          <div className="modal-content2">
            {/* Close button */}
            <div className="modal2-heading">
              <span className="close2"  onClick={closeModal}>
                &times;
              </span>
              <h4 className="me-5">Edit Views Count</h4>
            </div>
            {/* Modal title */}
            <div className="modal2-content mt-3">
              <input
                type="number"
                value={numberInput}
                onChange={handleInputChange}
                placeholder="Enter a number"
                className="rounded"
              />
            </div>

            {/* Button */}
            <button className="btn mt-3  py-1 px-3" onClick={handleButtonClick}>
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Post;
