import React, { useState, useEffect } from "react";
import Sidenav from '../Template/Sidenav'
import Navbar from '../Template/Navbar'
import Login from './LOgin'
import { Link } from 'react-router-dom'

const URL = process.env.REACT_APP_URL;

const Category = () => {
    const [deleteid, Setdeleteid] = useState('')
    const usertoken = sessionStorage.getItem('token')
    const [users, setUsers] = useState({ name: "", iconname: "" })
    const [category, setCategory] = useState('')
    const [file1, setFile1] = useState();
    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setUsers({ ...users, [name]: value })
    }

    const submitData = async (e) => {
        e.preventDefault();
        const { name } = users;
        const data = fetch(`${URL}/category/createcategory`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ name, iconname: file1 })
        });
        const response = await data;
        if (response.status === 403 || !response) {
            window.alert("invalid registration");
        } else {
            window.alert("registration sucessful");
            getcategory()
        }
    }
    const getcategory = () => {
        fetch(`${URL}/category/getallcategory`)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setCategory(data)
            })
    }
    useEffect(() => {
        getcategory()
    }, [])
    function handleChange1(e) {
        var formdata = new FormData();
        formdata.append("image", e?.target?.files[0]);
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        fetch("https://api.pinkspot.cc/uploading", requestOptions)
            .then(response => response.json())
            .then(result => setFile1(result?.data?.url))
    }

    function deleteUser(_id) {
        window.alert("Post removed")
    }
    if (!usertoken) {
        return <Login />
    }
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-2'>
                    <Sidenav />
                </div>
                <div className='col-md-10'>
                    <Navbar />

                    <div className="category-filter">
                        <form onSubmit={submitData}>
                            <div className='row'>
                                <div className='col-md-4'>

                                    <div className="mb-3 mt-3">
                                        <input type="text" className="form-control" id="subcategory" placeholder="Add Category" value={users.name} name="name" onChange={handleChange} />
                                    </div>


                                </div>
                                <div className='col-md-4'>
                                    <div className="mb-3 mt-3">
                                        {/* <input type="text" className="form-control" id="subcategory" placeholder="Add iconname" value={users.iconname} name="iconname" onChange={handleChange} /> */}
                                        <input type="file" id="file" name="filename" onChange={handleChange1} />
                                        {file1 ? <img src={file1} alt='xyz' /> : <img src={require("../img/images.png")} alt='xyz' />}
                                    </div>

                                </div>
                                <div className='col-md-4'>
                                    <div className="mb-3 mt-3">
                                        <button type="submit" className="btn ">Submit</button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div class="table-responsive text-center mt-5 mb-5">
                        <table class="table ">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Category-name</th>
                                    <th>Iconname</th>
                                    <th>View</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {category?.data?.map((val, index) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{val.name}</td>
                                            <td>{val.iconname}</td>


                                            <td> <Link to="/view-category" state={{ id: val._id }}><span class="material-symbols-outlined" style={{ color: 'gray' }}>visibility</span></Link></td>
                                            <td> <Link to="/edit-category" state={{ id: val._id, name: val.name, iconname: val.iconname, seotitle: val?.seotitle, seodescription: val?.seodescription, seoimageurl: val?.seoimageurl, seokeyword: val?.seokeyword }}><span class="material-symbols-outlined" style={{ color: 'gray' }}>edit</span></Link></td>
                                            <td> <span style={{ cursor: "pointer", color: 'gray' }} data-bs-toggle="modal" data-bs-target="#myModal" class="material-symbols-outlined" onClick={() => { Setdeleteid(val._id) }}>delete</span></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            <div className="modal fade" id="myModal">
                <div className="modal-dialog">
                    <div className="modal-content">


                        <div className="modal-header">

                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>


                        <div className="modal-body">
                            Do You Want To Delete Permanently
                        </div>


                        <div className="modal-footer">
                            <button type="button" className="btn" data-bs-dismiss="modal" onClick={() => deleteUser(deleteid)}>Yes</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Category