import React, { useState, useEffect } from 'react'
import Sidenav from '../Template/Sidenav'
import Navbar from '../Template/Navbar'
import { useLocation } from 'react-router-dom'
// import { Link } from 'react-router-dom'
const URL = process.env.REACT_APP_URL;



const Editsubcategory = () => {
  const location = useLocation();
  const { id, name, iconname, formid } = location.state;

  const [user, setUser] = useState({ name: "", iconname: "", formid: "" });
  useEffect(() => {
    setUser({ name, iconname, formid }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // let name, value
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUser({ ...user, [name]: value });
  }
  const handleSubmit = () => {
    const postURL = `${URL}/category/updateSubcategory/${id}`
    const { name, iconname } = user;

    fetch(postURL, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, iconname })

    })
      .then(res => {
        return res.json();
      }).then(data => {
        window.alert("profile updated");
      })
  }
  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-2'>
            <Sidenav />
          </div>
          <div className='col-md-10'>
            <Navbar />
            <form>
              <div className='row'>
                <div className='col-md-3'>

                  <div className="mb-3 mt-3">
                    <input type="text" className="form-control" id="subcategory" placeholder="Edit subcategory" value={user.name} name="name" onChange={handleChange} />
                  </div>


                </div>
                <div className='col-md-3'>
                  <div className="mb-3 mt-3">
                    <input type="text" className="form-control" id="subcategory" placeholder="Edit iconname" value={user.iconname} name="iconname" onChange={handleChange} />
                  </div>

                </div>
                <div className='col-md-3'>
                  <div className="mb-3 mt-3">
                    <select className="form-control form-select" name="formid" onChange={handleChange} >
                      <option value="form-one">form-one</option>
                      <option value="form-two">form-two</option>
                      <option value="form-three">form-three</option>
                    </select>
                  </div>

                </div>


              </div>
            </form>
            <div className='col-md-3'>
              <div className="mb-3 mt-3">
                <button type="submit" className="btn " onClick={() => handleSubmit()}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Editsubcategory