import React from 'react'
import Navbar from '../Template/Navbar'
import Sidenav from '../Template/Sidenav'
import Login from './LOgin'
const URL = process.env.REACT_APP_URL;

const Formone = () => {
    const usertoken = sessionStorage.getItem('token')
    if (!usertoken) {
      return <Login />
  }
  return (
    <>
   
    <div className='container-fluid'>
  
      <div className='row'>
        <div className='col-md-2'>
          <Sidenav />
        </div>
        <div className='col-md-10'>
        <Navbar />
          <div className='dashboard'>
          <>
           
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h6 className='post-heading text-center'>Post your Ad</h6>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-1'></div>
                    <div className='col-md-10 post-form-border'>
                        <form className='add-post' action="/action_page.php">
                            <div className="mb-3 mt-3">
                                <label htmlFor="name" className="form-label">Name*</label>
                                <input type="text" className="form-control" id="name"  name="name" />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="name" className="form-label">Age*</label>
                                <input type="text" className="form-control" id="name"  name="name" />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="name" className="form-label">City*</label>
                                <input type="text" className="form-control" id="name"  name="name" />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="name" className="form-label">Ethnicity*</label>
                                <input type="text" className="form-control" id="name"  name="name" />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="name" className="form-label">Availability*</label>
                                <input type="text" className="form-control" id="name"  name="name" />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="name" className="form-label">Body Stats*</label>
                                <input type="text" className="form-control" id="name"  name="name" />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="name" className="form-label">Mobile Number*</label>
                                <input type="text" className="form-control" id="name"  name="name" />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="name" className="form-label">Height*</label>
                                <input type="text" className="form-control" id="name"  name="name" />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="name" className="form-label">Weight*</label>
                                <input type="text" className="form-control" id="name"  name="name" />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="name" className="form-label">Hair Colour*</label>
                                <input type="text" className="form-control" id="name"  name="name" />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="name" className="form-label">Eye Colour*</label>
                                <input type="text" className="form-control" id="name"  name="name" />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="name" className="form-label">Ad title*</label>
                                <input type="text" className="form-control" id="name"  name="name" />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="name" className="form-label">Description*</label>
                                <input type="text" className="form-control" id="name"  name="name" />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="name" className="form-label">Price*</label>
                                <input type="text" className="form-control" id="name"  name="name" />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="name" className="form-label">State *</label>
                                <input type="text" className="form-control" id="name"  name="name" />
                            </div>
                            <div className="mb-3 mt-3 upload-photo">
                            <h6>UPLOAD UP TO 20 PHOTOS</h6>
                                <div className='row'>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                    <div className='col-md-2'><input type="file" id="myFile" name="filename"/></div>
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* <div className='col-md-5'></div> */}
                    <div className='col-md-1'></div>
                </div>
            </div>
          
        </>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default Formone