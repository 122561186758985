import React from 'react'
import Navbar from '../Template/Navbar'
import Sidenav from '../Template/Sidenav'
import Login from './LOgin'
const URL = process.env.REACT_APP_URL;

const Formthree = () => {
    const usertoken = sessionStorage.getItem('token')
    if (!usertoken) {
        return <Login />
    }
    return (
        <>
           
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-2'>
                        <Sidenav />
                    </div>
                    <div className='col-md-10'>
                    <Navbar />
                        <div className='dashboard'>
                        <>
           
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h6 className='post-heading text-center'>Post your Ad</h6>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-1'></div>
                    <div className='col-md-10 post-form-border'>
                        <form className='add-post'  action="/">
                            <div className="mb-3 mt-3">
                                <label htmlFor="type" className="form-label">Type*</label><br />
                                <select className="form-select" name="type" >
                                    <option value="Apartments">Apartments</option>
                                    <option value="Builder Floors">Builder Floors</option>
                                    <option value="Farm Houses">Farm Houses</option>
                                    <option value="Houses & Villas">Houses & Villas</option>
                                </select>

                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="bedroom" className="form-label">Bedrooms</label><br />
                                <select className="form-select" name="bedroom" >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="4+">4+</option>
                                </select>

                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="bathroom" className="form-label">Bathrooms</label><br />
                                <select className="form-select" name="bathroom" >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="4+">4+</option>
                                </select>

                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="furnishing" className="form-label">Furnishing</label><br />
                                <select className="form-select" name="furnishing" >
                                    <option value="Furnished">Furnished</option>
                                    <option value="Semi-Furnished">Semi-Furnished</option>
                                    <option value="Unfurnished">Unfurnished</option>
                                </select>
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="constructionstatus" className="form-label">Construction Status</label><br />
                                <select className="form-select" name="constructionstatus" >
                                    <option value="New Launch">New Launch</option>
                                    <option value="Ready to Move">Ready to Move</option>
                                    <option value="Under Construction">Under Construction</option>
                                </select>
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="listedby" className="form-label">Listed by</label><br />
                                <select className="form-select" name="listedby" >
                                    <option value="Builder">Builder</option>
                                    <option value="Dealer">Dealer</option>
                                    <option value="Owner">Owner</option>
                                </select>

                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="superbuiltuparea" className="form-label">Super Builtup area (ft²) *</label>
                                <input type="text" className="form-control" id="superbuiltuparea" name="superbuiltuparea"  />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="carpetarea" className="form-label">Carpet Area (ft²) *</label>
                                <input type="text" className="form-control" id="carpetarea" name="carpetarea"  />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="maintenance" className="form-label">Maintenance (Monthly)</label>
                                <input type="text" className="form-control" id="maintenance" name="maintenance"  />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="totalfloors" className="form-label">Total Floors</label>
                                <input type="text" className="form-control" id="totalfloors" name="totalfloors"  />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="floorno" className="form-label">Floor No</label>
                                <input type="text" className="form-control" id="floorno" name="floorno"  />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="carparking" className="form-label">Car Parking</label><br />
                                <select className="form-select" name="carparking" >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="4+">4+</option>
                                </select>

                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="facing" className="form-label">Facing</label>
                                <input type="text" className="form-control" id="facing" name="facing"  />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="projectname" className="form-label">Project Name</label>
                                <input type="text" className="form-control" id="projectname" name="projectname"  />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="title" className="form-label">Ad title *</label>
                                <input type="text" className="form-control" id="title" name="title"  />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="description" className="form-label">Description *</label>
                                <input type="text" className="form-control" id="description" name="description"  />
                            </div>
                            <div className="mb-3 mt-3">
                                <label htmlFor="price" className="form-label">Price*</label>
                                <input type="text" className="form-control" id="price" name="price"  />
                            </div>
                            <div className="mb-3 mt-3 upload-photo">
                          <h6>UPLOAD UP TO 20 PHOTOS</h6>
                          <div className='row'>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                          </div>
                          <div className='row mt-3'>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                          </div>
                          <div className='row mt-3'>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                          </div>
                          <div className='row mt-3'>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>
                            <div className='col-md-2'><input type="file" id="myFile" name="filename" /></div>

                          </div>
                        </div>
                          
                        </form>

                    </div>
                    {/* <div className='col-md-5'></div> */}
                    <div className='col-md-1'></div>
                </div>
            </div>
          
        </>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Formthree