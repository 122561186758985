import React, { useState, useEffect } from 'react'
import Sidenav from '../Template/Sidenav'
import Navbar from '../Template/Navbar'
import { useLocation } from 'react-router-dom'
// import { Link } from 'react-router-dom'

const URL = process.env.REACT_APP_URL;


const Editcategory = () => {
  const location = useLocation();
  const { id, name, iconname, seotitle, seodescription, seoimageurl, seokeyword } = location.state;
  const [user, setUser] = useState({ name: "", iconname: "", seotitle: "", seodescription: "", seoimageurl: "", seokeyword: "" });
  const [file1, setFile1] = useState();
  // let name, value
  const HandleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUser({ ...user, [name]: value });
  }
  useEffect(() => {
    setUser({ name, iconname, seotitle, seodescription, seoimageurl, seokeyword });// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = () => {
    const postURL = `${URL}/category/updatecategory/${id}`
    const { name, seotitle, seodescription, seoimageurl, seokeyword } = user;

    fetch(postURL, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, iconname: file1, seotitle, seodescription, seoimageurl, seokeyword })

    })
      .then(res => {
        return res.json();
      }).then(data => {
        window.alert("profile updated");
      })
  }

  function handleChange1(e) {
    var formdata = new FormData();
    formdata.append("image", e?.target?.files[0]);
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    fetch("https://api.pinkspot.cc/uploading", requestOptions)
      .then(response => response.json())
      .then(result => setFile1(result?.data?.url))
  }
  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-2'>
            <Sidenav />
          </div>
          <div className='col-md-10'>
            <Navbar />
            <form >
              <div className='row'>
                <div className='col-md-4'>

                  <div className="mb-3 mt-3">
                    <label for="country">Category Name:</label>

                    <input type="text" className="form-control" id="name" placeholder="Update Category" value={user.name} name="name" onChange={HandleInput} />
                  </div>


                </div>
                <div className='col-md-4'>
                  <div className="mb-3 mt-3">
                    <label for="country">SEO TITLE:</label>
                    <input type="text" className="form-control" id="iconname" placeholder="SEO TITLE" value={user?.seotitle} name="seotitle" onChange={HandleInput} />
                  </div>

                </div>
                <div className='col-md-4'>
                  <div className="mb-3 mt-3">
                    <label for="country">SEO DESCRIPTION:</label>
                    <input type="text" className="form-control" id="iconname" placeholder="Seo Description" value={user?.seodescription} name="seodescription" onChange={HandleInput} />
                  </div>

                </div>
                <div className='col-md-6'>
                  <div className="mb-3 mt-3">
                    <label for="country">SEO IMAGE:</label>
                    <input type="text" className="form-control" id="iconname" placeholder="Seo Image Url" value={user?.seoimageurl} name="seoimageurl" onChange={HandleInput} />
                  </div>

                </div>
                <div className='col-md-6'>
                  <div className="mb-3 mt-3">
                    <label for="country">SEO KEYWORDS:</label>
                    <input type="text" className="form-control" id="iconname" placeholder="Seo Keyword" value={user?.seokeyword} name="seokeyword" onChange={HandleInput} />
                  </div>

                </div>
                <div className='col-md-4'>
              <label for="country">Category Icon:</label>
              <div className="mb-3 mt-3">
                {user.iconname ? <img src={user.iconname} alt='xyz' /> : <img src={require("../img/images.png")} alt='xyz' />}
              </div>
            </div>
            <div className='col-md-4'>
            <label for="country">Change Icon:</label>
              <div className="mb-3 mt-3">
                <input type="file" id="file" name="filename" onChange={handleChange1} />
                {file1 ? <img src={file1} alt='xyz' /> : <img src={require("../img/images.png")} alt='xyz' />}
              </div>
            </div>
              </div>
            </form>
           
            <div className='col-md-4'>
              <div className="mb-3 mt-3">
                <button type="submit" className="btn " onClick={() => handleSubmit()}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Editcategory