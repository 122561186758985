import React, { useEffect, useState } from 'react'
import Sidenav from '../Template/Sidenav'
import Navbar from '../Template/Navbar'
const URL = process.env.REACT_APP_URL;

const Home = () => {
  const [user, setUser] = useState('')

  const getuser = () => {
    fetch(`${URL}/admin/getAllUsers`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        setUser(data)
      })
  }
  useEffect(() => {
    getuser()
  }, [])
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-2'>
          <Sidenav />
        </div>
        <div className='col-md-10'>
          <Navbar />
          <div className='row mt-5'>
            <div className='col-md-3'>
              <div className=' dashboard-inner'>
                <div className='dashboard-text'>
                  <h5>Members</h5>
                  <h4>{user?.TotalUsers}</h4>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className=' dashboard-inner'>
                <div className='dashboard-text'>
                  <h5>Total Amount </h5>
                  <h4>0</h4>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className=' dashboard-inner'>
                <div className='dashboard-text'>
                  <h5>Banners </h5>
                  <h4>0</h4>
                </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className=' dashboard-inner'>
                <div className='dashboard-text'>
                  <h5>Gallaries </h5>
                  <h4>0</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home