import React, { useState, useEffect } from 'react'
import Sidenav from '../Template/Sidenav'
import Navbar from '../Template/Navbar'
import { useLocation, useNavigate } from 'react-router-dom'
const URL = process.env.REACT_APP_URL;

const Editpost = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id, keywords, metatitle, metadescription } = location.state;
    const [pages, setpages] = useState({ keywords: keywords, metatitle: metatitle, metadescription: metadescription });

    const HandleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setpages({ ...pages, [name]: value });
    }
    useEffect(() => {
        setpages({ keywords, metatitle, metadescription });// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = () => {
        const postURL = `${URL}/postad/update_Post/${id}`
        const { keywords, metatitle, metadescription } = pages;

        fetch(postURL, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ keywords, metatitle, metadescription })
        })
            .then(res => {
                return res.json();
            }).then(data => {
                navigate('/post')
                window.alert(" updated Successfully ");
            })
    }


    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-2'>
                    <Sidenav />
                </div>
                <div className='col-md-10'>
                    <Navbar />
                    <form >
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="mb-3 mt-3 w-75">
                                    <label for="country">Seotitle:</label>
                                    <input type="text" className="form-control" id="iconname" value={pages.metatitle} name="metatitle" onChange={HandleInput} />
                                </div>

                            </div>
                            <div className='col-md-12'>
                                <div className="mb-3 mt-3 w-75">
                                    <label for="country">Seo Keyword:</label>
                                    <input type="text" className="form-control" id="iconname" value={pages?.keywords} name="keywords" onChange={HandleInput} />
                                </div>

                            </div>
                            <div className='col-md-12'>
                                <label for="country">Seo Description:</label>
                                <div className="mb-3 mt-3">
                                    <textarea className='w-75' type="text" id="iconname" value={pages?.metadescription} name="metadescription" onChange={HandleInput} rows="6" />
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className='col-md-4'>
                        <div className="mb-3 mt-3">
                            <button type="submit" className="btn " onClick={() => handleSubmit()}>Submit</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Editpost