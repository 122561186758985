import React, { useEffect, useState } from 'react'
import Sidenav from '../Template/Sidenav'
import Navbar from '../Template/Navbar'
import Login from './LOgin'
import { Link } from 'react-router-dom'
const URL = process.env.REACT_APP_URL;

const Users = () => {
  const usertoken = sessionStorage.getItem('token')
  const [user, setUser] = useState('')

  const getuser = () => {
    fetch(`${URL}/admin/getAllUsers`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        setUser(data)
      })
  }
  useEffect(() => {
    getuser()
  }, [])
  if (!usertoken) {
    return <Login />
  }

  const handleStatus = async (id,e) => {
    const value = e.target.value;
    fetch(`${URL}/users/updateUserStatus/${id}`, {
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ status:value })
       
		}).then((result) => {
			result.json().then((res) => {
        getuser();
			})
		})
        };
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-2'>
          <Sidenav />
        </div>
        <div className='col-md-10'>
          <Navbar />
          <form >
            <div className='row'>
              <div className='col-md-3'>

                <div className="mb-3 mt-3">
                  {/* <input type="text" className="form-control" id="subcategory" placeholder="Search" /> */}
                </div>


              </div>
              <div className='col-md-3'>

              </div>
              <div className='col-md-3'>
              </div>
              <div className='col-md-1'>
              </div>
              <div className='col-md-2'>
                <div className="mb-3 mt-3">
                  {/* <button type="submit" className="btn ">Back</button> */}
                </div>
              </div>
            </div>
          </form>

          <div class="table-responsive text-center mt-5">
            <table class="table ">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Image</th>
                  {/* <th>Status</th> */}
                  <th>Change Status</th>
                  <th>View</th>


                </tr>
              </thead>
              <tbody>
                {user?.data?.map((val, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{val.phone}</td>
                      <td>{val.email}</td>



                      <td>{val.image !== "" ? <img className='profile-img' src={val.image} alt="sgdg" /> : <img className='profile-img' src={require("../img/user.jpg")} alt="sgdg" />}</td>
                      {/* <td>{val.status}</td> */}
                      <td>
                        <select
                          className="form-control"
                          id="exampleSelectGender"
                          name="status"
                          onChange={(e) => handleStatus(val._id, e)}
                        >
                          <option value="" disabled selected hidden>{val.status}</option>
                          <option value="ACTIVE" >Active</option>
                          <option value="INACTIVE"  >Inactive</option>
                          <option value="DELETE" >Delete</option>
                        </select>
                      </td>
                      <td><Link to="/user-detail" state={{ val: val }}><span class="material-symbols-outlined" style={{ color: 'gray' }}>visibility</span></Link></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Users